<template>
  <div id="redeemCode">
    <template v-if="$route.name == 'marketingRedeem'">
      <!---------------------------------------------------- 兑换码 -------------------------------------------------->
      <template>
        <!-- 顶部搜索 -->
        <div class="header mb10">
          <el-button
            v-if="showRemoveNew"
            type="primary"
            class="mr30"
            @click="$router.push({ path: '/marketingRedeem/addRedeemCode' })"
          >
            新建
          </el-button>
          <el-checkbox
            v-model="formData.unexpired"
            :true-label="1"
            :false-label="2"
            @change="change1"
          >
            只看未过期
          </el-checkbox>
          <el-checkbox
            v-model="formData.overdue"
            :true-label="1"
            :false-label="2"
            class="ml10"
            @change="change2"
          >
            只看已过期
          </el-checkbox>
          <el-input
            style="width: 338px"
            size="medium"
            v-model="formData.name"
            class="input-with-select fr"
            :placeholder="
              formData.type == 1 || formData.type == ''
                ? '输入活动名称搜索'
                : '输入兑换码搜索'
            "
          >
            <el-select
              size="medium"
              v-model="formData.type"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="活动名称" value="1"></el-option>
              <el-option label="兑换码" value="2"></el-option>
            </el-select>
          </el-input>
        </div>
        <!-- 表格 -->
        <pagination2
          ref="child"
          :option="formData"
          url="/Redeem/redeemActionList"
          @complete="complete"
        >
          <template v-slot:default="{ tableData }">
            <el-table
              :data="tableData"
              :header-cell-style="{
                background: 'rgba(245,245,245,1)',
                color: '#333333',
              }"
            >
              <el-table-column prop="name" label="名称" />
              <el-table-column label="过期时间">
                <template slot-scope="scope">
                  {{ scope.row.validity | formatTimeStamp }}
                </template>
              </el-table-column>
              <el-table-column label="已使用">
                <template slot-scope="scope">
                  {{ scope.row.used_stock }} / {{ scope.row.stock }}
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">
                  {{ scope.row.out_date ? '进行中' : '已结束' }}
                </template>
              </el-table-column>
              <el-table-column label="创建人" prop="uname">
                <template slot-scope="{ row }">
                  {{ row.uname || '-' }}
                </template>
              </el-table-column>
              <el-table-column label="创建时间">
                <template slot-scope="{ row }">
                  {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
                </template>
              </el-table-column>
              <el-table-column label="操作" width="250">
                <template slot-scope="scope">
                  <el-button
                    v-if="showRemoveEdit"
                    size="mini"
                    type="text"
                    @click="goEdit(scope.row.redeem_id)"
                    :disabled="!scope.row.out_date || !scope.row.is_adder"
                  >
                    编辑
                  </el-button>
                  <el-divider
                    direction="vertical"
                    v-if="showRemoveEdit"
                  ></el-divider>
                  <el-button
                    size="mini"
                    type="text"
                    @click="goDetail(scope.row)"
                  >
                    详情
                  </el-button>
                  <el-divider
                    direction="vertical"
                    v-if="showRemoveExport"
                  ></el-divider>
                  <el-button
                    v-if="showRemoveExport"
                    size="mini"
                    type="text"
                    @click="exportExcel(scope.row.redeem_id)"
                    :disabled="!scope.row.is_adder"
                  >
                    导出
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <template #footer>
            <div class="tipfooter">
              <span v-if="showtip">已查询到该兑换码所在的活动</span>
            </div>
          </template>
        </pagination2>
      </template>
    </template>
    <!--End-->
    <router-view />
  </div>
</template>
<script>
import Clipboard from 'clipboard' //点击复制功能
import background from '@/mixin/background'
export default {
  name: 'redeemCode',
  mixins: [background],
  data() {
    return {
      postval: {},

      //分享海报弹窗
      status: false,

      // 分享弹窗
      dialogIsShow_1: false,
      copyDOM: '',
      copyUrl: '',
      // tab绑定
      activeName: '0',
      // 表单数据
      formData: {
        type: '1',
        name: '',
        unexpired: 1,
        overdue: 2,
      },
      // 表格信息
      tableData: [],
      total: 0,
    }
  },
  created() {
    this.update()
    if (this.$route.query.type) {
      switch (this.$route.query.type) {
        case 1:
          this.activeName = '0'
          break
        case 2:
          this.activeName = '1'
      }
    }
  },
  watch: {
    $route(to, from) {
      if (
        from.name == 'addExchangeActionList' ||
        from.name == 'addRedeemCode'
      ) {
        if (Object.keys(from.query).length > 0) {
          this.setRoutePrompt()
        } else {
          this.formData.page = 1
          this.formData.name = ''
          this.setRoutePrompt()
        }
      } else if (
        from.name == 'editExchangeActionList' ||
        from.name == 'editRedeemCode'
      ) {
        this.setRoutePrompt()
      }
    },
  },
  computed: {
    showtip() {
      return (
        this.formData.name &&
        this.formData.type == '2' &&
        Number(this.total) > 0
      )
    },
    //新建
    showRemoveNew() {
      return this.$store.state.roots.includes(145)
    },
    //编辑
    showRemoveEdit() {
      return this.$store.state.roots.includes(146)
    },
    //新建提取码
    showExtractionCodeBtn() {
      return this.$store.state.roots.includes(167)
    },
    //编辑提取码
    showExtractionCodeEditBtn() {
      return this.$store.state.roots.includes(168)
    },
    //导出
    showRemoveExport() {
      return this.$store.state.roots.includes(147)
    },
    //作废兑换码
    showRemoveExchangecode() {
      return this.$store.state.roots.includes(148)
    },
    //作废提取码
    showRemoveExtractioncode() {
      return this.$store.state.roots.includes(149)
    },
  },
  mounted() {},
  methods: {
    complete(val) {
      this.total = val.total
    },
    //海报分享
    poster(val) {
      this.postval = val
      this.status = true
      this.$nextTick(() => {
        this.$refs.posterShareref.create_ewm()
      })
    },
    //初始化数据
    update() {
      if (this.activeName == '0') {
        typeof localStorage.exchangeCodeNo == 'undefined'
          ? window.localStorage.setItem('exchangeCodeNo', 1)
          : (this.formData.unexpired = Number(
              localStorage.getItem('exchangeCodeNo')
            ))

        typeof localStorage.exchangeCodeYes == 'undefined'
          ? window.localStorage.setItem('exchangeCodeYes', 2)
          : (this.formData.overdue = Number(
              localStorage.getItem('exchangeCodeYes')
            ))
      } else {
        typeof localStorage.extractionCodeNo == 'undefined'
          ? window.localStorage.setItem('extractionCodeNo', 1)
          : (this.formData.unexpired = Number(
              localStorage.getItem('extractionCodeNo')
            ))
        typeof localStorage.extractionCodeYes == 'undefined'
          ? window.localStorage.setItem('extractionCodeYes', 2)
          : (this.formData.overdue = Number(
              localStorage.getItem('extractionCodeYes')
            ))
      }
    },
    change1(val) {
      if (this.activeName == '0') {
        window.localStorage.setItem('exchangeCodeNo', val)
      } else {
        window.localStorage.setItem('extractionCodeNo', val)
      }
      if (val == 1) {
        this.formData.overdue = 2
        if (this.activeName == '0') {
          window.localStorage.setItem('exchangeCodeYes', 2)
        } else {
          window.localStorage.setItem('extractionCodeYes', 2)
        }
      }
    },
    change2(val) {
      if (this.activeName == '0') {
        window.localStorage.setItem('exchangeCodeYes', val)
      } else {
        window.localStorage.setItem('extractionCodeYes', val)
      }

      if (val == 1) {
        this.formData.unexpired = 2
        if (this.activeName == '0') {
          window.localStorage.setItem('exchangeCodeNo', 2)
        } else {
          window.localStorage.setItem('extractionCodeNo', 2)
        }
      }
    },

    setRoutePrompt() {
      if (this.activeName == 0) {
        // this.$refs.child.reset()
        this.$parent.childPageOptions[0].name = '兑换码'
        this.$parent.childPageOptions[0].iconPrompt = 2
        this.$parent.childPageOptions[0].prompt =
          '提示：兑换码的编辑、导出、分享仅支持创建人操作'
      } else {
        // this.$refs.exchangeActionList.reset()
        this.$parent.childPageOptions[0].name = '提取码'
        this.$parent.childPageOptions[0].iconPrompt = 2
        this.$parent.childPageOptions[0].prompt =
          '提示：提取码的编辑、分享仅支持创建人操作'
      }
    },

    // 分享提取码
    shareOnce(obj) {
      this.dialogIsShow_1 = true
      this.$http({
        url: '/Exchange/getPlayUrl',
        callback: res => {
          this.copyDOM = `<p>提取链接： ${res.data +
            obj.exchange_id}</p><br/><p>提取码： ${obj.exchange_code}</p>`
          this.copyUrl = `提取链接：${res.data + obj.exchange_id}     提取码：${
            obj.exchange_code
          }`
        },
      })
    },
    // 前往详情页
    goDetail(obj) {
      let url = ''
      url = '/marketingRedeem/editRedeemCode'

      this.$router.push({
        path: url,
        query: {
          redeem_id: obj.redeem_id,
          name: obj.name,
          exchange_id: obj.exchange_id,
        },
      })
    },
    // 编辑id
    goEdit(id) {
      let url = ''
      url = '/marketingRedeem/addRedeemCode'

      this.$router.push({
        path: url,
        query: {
          redeem_id: id,
          exchange_id: id,
        },
      })
    },
    // 删除兑换码/提取码
    deleteOnce(id) {
      let url = '',
        formData = [],
        title = '',
        tip = ''
      if (this.activeName == 0) {
        url = '/Redeem/cancelRedeemAction'
        formData.redeem_id = id
        title = '删除兑换码'
        tip = '删除后已兑换的学员有效，未兑换的码将作废，不可恢复！'
      } else {
        url = '/Exchange/cancelExchangeAction'
        formData.exchange_id = id
        title = '删除提取码'
        tip = '删除后已提取的学员有效，未提取的码将失效，不可恢复！'
      }
      this.$confirm(tip, title, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http({
          url,
          data: formData,
          callback: () => {
            if (this.activeName == 0) {
              this.$refs.child.reset()
            } else {
              this.$refs.exchangeActionList.reset()
            }
          },
        })
      })
    },
    // 导出兑换码
    exportExcel(redeem_id) {
      window.location.href = `/Redeem/exportExcel?redeem_id=${redeem_id}`
    },
    //复制
    copy() {
      var that = this
      var clipboard = new Clipboard('.info_right')
      clipboard.on('success', () => {
        that.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
        //这里你如果引入了elementui的提示就可以用，没有就注释即可
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', () => {
        // 不支持复制
        that.$root.prompt('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    },
  },
}
</script>
<style lang="less" scoped>
.tipfooter {
  font-size: 14px;
  color: #ff3535;
  text-align: center;
  margin-top: 30px;
}
#redeemCode {
  background: rgba(255, 255, 255, 1);
  margin: 20px;
  box-sizing: border-box;
  padding: 20px;
  .el-tabs {
    position: relative;
    /deep/ .el-tabs__content {
      position: absolute;
      left: 130px;
      top: 7px;
      .el-tab-pane {
        display: block !important;
      }
      .info {
        cursor: pointer;
        color: rgba(102, 102, 102, 1);
        font-size: 14px;
      }
    }
  }
  .header {
    height: 50px;
    width: 100%;
    display: block;
    ::v-deep .input-with-select {
      .el-input__inner {
        color: #333333;
      }
      .el-input-group__prepend {
        background-color: #fff;
        width: 80px;
      }
    }
  }
  .el-divider {
    background: rgba(27, 157, 151, 1);
  }
  .send_verification {
    /deep/ .el-dialog {
      .el-dialog__body {
        .box_info {
          background: rgba(249, 249, 249, 1);
          border-radius: 2px;
          border: 1px solid rgba(232, 232, 232, 1);
          box-sizing: border-box;
          padding: 10px;
          font-size: 13px;
          color: rgba(51, 51, 51, 1);
          line-height: 20px;
        }
      }
    }
  }
}
</style>
